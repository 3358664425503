// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import jQuery from 'jquery';
import $ from 'jquery';
import * as bootstrap from "bootstrap"

$(function () {
    $.ajaxSetup({
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')}
    });
});

window.crud_edit_row = function (div_id, url) {
    $.ajax(url, {
        data: {},
        dataType: "html",
        method: "GET",
        success: function (data) {
            $("#" + div_id).replaceWith(data);
        },
        error: function () {
            alert("Update error.");
        },
    })
}

window.crud_edit_row_submit = function (div_id, url) {
    let data = {};

    $("#" + div_id).find(":input").each(function () {
        let v = $(this).val();
        let n = $(this).attr("data-name");
        data[n] = v;
    });

    $.ajax(url, {
        data: data,
        dataType: "html",
        method: "PATCH",
        success: function (data) {
            $("#" + div_id).replaceWith(data);
        },
        error: function () {
            alert("Update error.");
        },
    })
}

window.crud_edit_row_cancel = function (div_id, url) {
    $.ajax(url, {
        data: {},
        dataType: "html",
        method: "PATCH",
        success: function (data) {
            $("#" + div_id).replaceWith(data);
        },
        error: function () {
            alert("Update error.");
        },
    })
}

window.crud_delete_row = function (div_id, url) {
    if (confirm("Are you sure?")) {
        $.ajax(url, {
            method: "DELETE",
            success: function () {
                $("#" + div_id).remove();
            },
            error: function () {
                alert("Error deleting the row.");
            },
        })
    }
}

window.crud_create_show = function (div_id, url) {
    $.ajax(url, {
        data: {},
        dataType: "html",
        method: "GET",
        success: function (data) {
            $("#" + div_id + "-new").replaceWith(data);
        },
        error: function () {
            alert("Create error.");
        },
    })
}

window.crud_create_submit = function (div_id, url) {
    let data = {};

    $("#" + div_id + "-new").find(":input").each(function () {
        let v = $(this).val();
        let n = $(this).attr("data-name");
        data[n] = v;
    });

    $.ajax(url, {
        data: data,
        dataType: "html",
        method: "POST",
        success: function (data, status, xhr) {
            if (xhr.status === 201) {
                $("#" + div_id + "-new").addClass("d-none");
                $("#" + div_id + "-table").prepend(data);
            } else {
                $("#" + div_id + "-new").replaceWith(data);
            }
        },
        error: function () {
            alert("Create error.");
        },
    })
}

window.crud_create_cancel = function (div_id) {
    $("#" + div_id + "-new").addClass("d-none");
}

window.riskapi_get_risk = function (url) {
    let coin = $('#risk-coin').val();
    let address = $("#risk-address").val();

    $.ajax(url, {
        data: {
            coin: coin,
            address: address
        },
        dataType: "json",
        method: "GET",
        success: function (data) {
            $("#risk-result-coin").text(data["coin"]);
            $("#risk-result-address").val(data["address"]);
            $("#risk-result-risk").val(data["risk"]);
            $("#risk-result-risk-number").text(data["risk"]);
            $("#risk-result").removeClass("d-none");
        },
        error: function () {
            $("#risk-result").addClass("d-none");
            alert("Error getting risk");
        },
    })
}

window.switch_tab = (n) => {
    $("#t1").addClass('d-none')
    $("#t2").addClass('d-none')
    $("#t3").addClass('d-none')
    $("#" + n).removeClass('d-none')
    $("#n-t1").removeClass("active")
    $("#n-t2").removeClass("active")
    $("#n-t3").removeClass("active")
    $("#n-" + n ).addClass("active")
}

window.risk_check = function () {
    let address = $("#risk-address").val();

    $.ajax("/risk_categories/check", {
        data: {
            address: address
        },
        dataType: "json",
        method: "GET",
        success: function (data) {
            $("#risk-response").text(JSON.stringify(data, null, 2));
            $("#risk-result").removeClass("d-none");
        },
        error: function () {
            $("#risk-result").addClass("d-none");
            alert("Error getting risk");
        },
    })
}
